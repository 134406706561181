<!-- Start Navbar Area -->
<header id="header">
  <div class="crake-nav {{location == '/digital-marketing' ? 'marketing-navbar' : ''}}">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/">
          <img src="/assets/img/trialist-logo.png" class="logo">
          Trialist
        </a>
        
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
          <ul class="navbar-nav nav ms-auto">
            <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Home</a></li>
            <li class="nav-item"><a routerLink="contact-us" class="nav-link" routerLinkActive="active">Contact</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<div id="header-search" class="header-search">
  <button type="button" class="close">×</button>
  <form class="header-search-form">
    <input type="search" value="" placeholder="Type here........" />
    <button type="submit" class="btn btn-primary">Search</button>
  </form>
</div>
<!-- End Search Box -->