<!-- Start SaaS Main Banner -->
<div class="main-banner saas-home">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="saas-image">
              <img src="assets/img/saas-shape/arrow.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="arrow">
              <img src="assets/img/saas-shape/box1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="box1">
              <img src="assets/img/saas-shape/boy1.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="boy1">
              <img src="assets/img/saas-shape/boy2.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="boy2">
              <img src="assets/img/saas-shape/boy3.png" class="wow animate__animated animate__bounceIn" data-wow-delay="0.6s" alt="boy3">
              <img src="assets/img/saas-shape/digital-screen.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="digital-screen">
              <img src="assets/img/saas-shape/filter1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="filter1">
              <img src="assets/img/saas-shape/filter2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="filter2">
              <img src="assets/img/saas-shape/filter3.png" class="wow animate__animated animate__rotateIn" data-wow-delay="0.6s" alt="filter3">
              <img src="assets/img/saas-shape/girl1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="girl1">
              <img src="assets/img/saas-shape/girl2.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="girl2">
              <img src="assets/img/saas-shape/monitor.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="monitor">
              <img src="assets/img/saas-shape/main-image.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="main-image.png">
            </div>
          </div>
          
          <div class="col-lg-6 col-md-12">
            <div class="hero-content pl-4">
              <h1>The future of<br>Clinical Research</h1>
              <p>The first and only, fully automated EHR-to-EDC connector that accurately transfers data in real time.</p>
              <br>
              <a routerLink="/contact-us" class="btn btn-primary">Request Demo</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="bg-gray shape-1"></div>
  <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
  <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
  <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
  <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
</div>
<!-- End SaaS Main Banner -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100 bg-gray">
  <div class="container">
    <div class="overview-box">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 overview-img">
          <img src="assets/img/whatwedo.png" alt="image">
        </div>
        
        <div class="col-lg-6 col-md-6 overview-content">
          <h3>What We Do</h3>
          <p>Trialist integrates into clinical sites to provide real time access to clean data from any EMR.</p>
          <p>Eliminate manual processes by streamlining manual research tasks such as transmitting data and screening patients from siloed systems.</p>
          <p>Trialist follows a strict security process to meet SOC-2, HITRUST CSF requirements, and aligns with HIPAA, GDPR, and 21 CFR 11 regulations.</p>
          <br>
          <a routerLink="/contact-us" class="btn btn-primary">Request Demo</a>
        </div>
      </div>
    </div>
  </div>
  <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
  <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
  <div class="bg-gray shape-1"></div>
  <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
  <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
  <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
  <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
  <div class="container">
      <div class="row text-center">
          <div class="col-lg-4 col-md-6">
              <div class="box piped">
                <div><img src="assets/img/third-party-logos/epic-logo.png"></div>
                <div><img src="assets/img/third-party-logos/cerner-logo.png"></div>
                <div class="blurb">MORE</div>
                <i class="icofont-bubble-right"></i>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="box">
                  <img src="assets/img/trialist-logo.png" alt="trialist-logo" class="trialist-box-logo">
                  <h3>Trialist</h3>
                  <span>Clincal Connect</span>
                  <i class="icofont-bubble-right"></i>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="box piped">
                <div><img src="assets/img/third-party-logos/castor-logo.png"></div>
                <div><img src="assets/img/third-party-logos/medidata-logo.png"></div>
                <div class="blurb">MORE</div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- End Platform Connect -->

<!-- Start SAAS Tools Area -->
<section class="saas-tools ptb-100 bg-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="tab">
          <ul class="tabs">
            <li><a href="#">
              <i class="icofont-dna"></i>
              <br>
              Sponsors
            </a></li>
            
            <li><a href="#">
              <i class="icofont-hospital"></i>
              <br>
              Clincal Sites
            </a></li>
          </ul>
          
          <div class="tab_content">
            <div class="tabs_item">
              <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-12 col-md-12">
                  <div class="tabs_item_content mt-0">
                    <div class="row">
                      <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                          <h3>Save Time</h3>
                          <p>Trialist connects sponsors directly to their site's EMR, providing clean data in real time.<br>&nbsp;</p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                          <h3>Improve Data Quality</h3>
                          <p>Trialist delivers clinical data in real time - copied directly from the EMR without human error.</p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                          <h3>Increase Productivity</h3>
                          <p>Trialist screening technology means staff can identify more patients, faster all without having to manage patient data.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="tabs_item">
              <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-12 col-md-12">
                  <div class="tabs_item_content mb-0">
                    <div class="row">
                      <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                          <h3>Increase Efficiency</h3>
                          <p>By freeing clinical staff from manual tasks like searching for patients or sending data, site's can take on more trials.</p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                          <h3>Accurate Data</h3>
                          <p>Trialist moves clean data meaning fewer on site visits and audits.</p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                          <h3>Smoother Trial Experience</h3>
                          <p>Trialist automations mean your people can hit milestones faster and handle the human tasks like caring for patients.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End SAAS Tools Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100 bg-gray">
  <div class="container">
    <div class="overview-box">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 overview-content">
          <h3>Who We Are</h3>
          <p>
            Trialist is on a mission to modernize clinical trials.
            Clinical trials are inherently a human endeavour and we intend to improve the experience.
            Our objective is to free people from manual, time consuming tasks like searching for candidates and moving data so they can spend time caring for patients and progressing trials forward.
            Through ai automation, we reduce the resources and costs of conducting a clinical trial
          </p>
        </div>
        <div class="col-lg-6 col-md-6 overview-img">
          <img src="assets/img/whoweare.png" alt="image">
        </div>
      </div>
    </div>
  </div>
  <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
  <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
  <div class="bg-gray shape-1"></div>
  <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
  <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
  <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
  <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
  <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area ptb-100">
  <div class="container">
      <div class="section-title">
        <h2>Request a Demo</h2>
        <div class="bar"></div>
        <p>Trialist is an agnostic EHR integration tool. We process any data to identify eligible patients faster and subsequently move their data to any target. We reduce costly errors, find the right patient and move trials forward, faster.</p>
      </div>
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-lg-6 col-md-12">
          <img src="assets/img/marketing.png" alt="image">
        </div>
        <div class="col-lg-6 col-md-12">
            <app-contact-form></app-contact-form>
        </div>
    </div>
  </div>
</section>
<!-- End Subscribe Area -->