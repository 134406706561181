import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-saas-landing',
    templateUrl: './saas-landing.component.html',
    styleUrls: ['./saas-landing.component.scss']
})
export class SaasLandingComponent implements OnInit {
    isMobile = false;
    constructor( private titleService: Title ) {
        this.titleService.setTitle('Trialist - The Future of Clinical Research');
    }

    ngOnInit() {
        // Hide some animations in mobile view
        if (window.innerWidth <= 768) { // 768px portrait
            this.isMobile = true;
        }
    }

}
