import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  constructor(
    private titleService: Title,
    private http: HttpClient
  ) {
      this.titleService.setTitle('Trialist - Contact Us');
  }

  ngOnInit() {
  }
}
