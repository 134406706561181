<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">  
  <div class="copyright-area">
    <div class="container">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-lg-4 col-md-4">
          <p>&copy; {{ year }} Trialist, LLC. All rights reserved.</p>
        </div>
        <div class="col-lg-4 col-md-4">
          <p>&nbsp;</p>
        </div>
        <div class="col-lg-4 col-md-4">
          <p>The future of clinical research using AI automation tools.</p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->